/* ========================================================================
 * Apricot's Filters
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/filter.scss";

import Popover from "./CBPopover";
import Utils from "./CBUtils";

/**
 * Filter Popover
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.popoverNode
 * @param {String} data.placement
 * @param {String} data.flipVariations
 * @param {Array} data.offset
 * @param {Number|Object} data.delay
 * @param {Boolean} data.closeOnClickOutside
 * @param {Boolean} data.disableHeightAdjustment
 * @param {Boolean} data.disableHeightAdjustmentAria
 * @param {Function} data.onShow
 * @param {Function} data.onHide
 * @returns {{hide: Function}}
 * @returns {{show: Function}}
 * @returns {{updateTrigger: Function}}
 * @returns {{destroy: Function}}
 */

const CBFilter = (data = {}) => {
  const defaultData = {
    elem: null,
    popoverNode: null,
    placement: "bottom-start",
    flipVariations: "top-start",
    offset: [0, 8],
    delay: {
      show: 200,
      hide: 100,
    },
    closeOnClickOutside: true,
    disableHeightAdjustment: false,
    disableHeightAdjustmentAria: true,
    onShow: null,
    onHide: null,
  };
  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  if (!Utils.elemExists(elem)) return null;

  let popoverNode = null;
  let header = null;
  let footer = null;

  let shown = false;
  let adjustableContent = null;
  let triggerLabel = elem.innerHTML.trim();

  let popoverIns = null;
  const init = () => {
    elem.filter = "cb";
    popoverNode =
      data.popoverNode || document.querySelector(`#${elem.getAttribute("aria-controls")}`);
    adjustableContent = popoverNode.querySelector(".cb-popover-content");
    header = popoverNode.querySelector(".cb-popover-header");
    footer = popoverNode.querySelector(".cb-popover-footer");

    triggerLabel = elem.innerHTML.trim();

    popoverIns = Popover({
      elem: elem,
      popoverNode: popoverNode,
      placement: data.placement,
      flipVariations: data.flipVariations,
      offset: data.offset,
      delay: data.delay,
      closeOnClickOutside: data.closeOnClickOutside,
      filter: true,
    });

    elem.addEventListener("click", (e) => {
      e.preventDefault();

      if (Utils.hasClass(elem, "cb-filter-open")) {
        popoverHide(e);
      } else {
        popoverShow(e);
      }
    });

    elem.addEventListener("apricot_popover_hide", (e) => {
      popoverHide(e);
    });

    if (!data.disableHeightAdjustment && Utils.elemExists(adjustableContent)) {
      Utils.addClass(popoverNode, "cb-popover-content-adjust");
      elem.addEventListener("apricot_popover_show", () => {
        shown = true;
        calculateHeight();
      });

      elem.addEventListener("apricot_popover_hide", () => {
        shown = false;

        resetHeight();
      });
    }
  };

  const calculateHeight = () => {
    if (!shown) return;

    let ch = Utils.height(popoverNode);
    let mh = Utils.height(adjustableContent);
    let hh = 0;
    let fh = 0;
    let newHeight = 0;

    if (header) hh = Utils.outerHeight(header);
    if (footer) {
      fh = Utils.outerHeight(footer);
    }

    newHeight = ch - 40 - hh - fh;
    if (mh > newHeight) {
      adjustableContent.style.overflowY = "auto";
      adjustableContent.style.height = newHeight + "px";
      if (!data.disableHeightAdjustmentAria) {
        Utils.attr(adjustableContent, "tabindex", "0");
        Utils.attr(adjustableContent, "role", "region");
        Utils.attr(adjustableContent, "aria-label", "scrollable content");
      }
    } else {
      resetHeight();
    }
  };

  const resetHeight = () => {
    adjustableContent.style.overflowY = "hidden";
    adjustableContent.style.height = "auto";
    if (!data.disableHeightAdjustmentAria) {
      Utils.removeAttr(adjustableContent, "tabindex");
      Utils.removeAttr(adjustableContent, "role");
      Utils.removeAttr(adjustableContent, "aria-label");
    }
  };

  const popoverShow = (e) => {
    data.onShow && data.onShow(e);
    Utils.addClass(elem, "cb-filter-open");
  };
  const popoverHide = (e) => {
    data.onHide && data.onHide(e);
    Utils.removeClass(elem, "cb-filter-open");
  };
  const hide = () => {
    popoverIns.hide();
  };
  const show = () => {
    popoverIns.show();
  };

  /**
   * Updates the label for trigger
   * @method CBFilter#updateTrigger
   * @memberof CBFilter
   * @param {Number} count - Number of selected filters
   * @param {String} label - Actual label of the trigger (optional)
   */
  const updateTrigger = (count = 0, label = null) => {
    let newLabel = triggerLabel;
    if (label) newLabel = label;

    if (count > 0) {
      newLabel = `${newLabel} (${count})`;
    } else {
      newLabel = triggerLabel;
    }
    elem.innerHTML = newLabel;

    if (triggerLabel !== newLabel) {
      Utils.addClass(elem, "cb-filter-active");
    } else {
      Utils.removeClass(elem, "cb-filter-active");
    }
  };
  const destroy = () => {
    if (elem.filter === "cb") {
      elem.filter = null;
      // if (!data.disableHeightAdjustment) {
      // 	window.removeEventListener('resize', calculateHeight);
      // }
    }
  };

  if (elem.filter !== "cb") {
    init();
  }

  return {
    hide: hide,
    show: show,
    updateTrigger: updateTrigger,
    destroy: destroy,
  };
};

export default CBFilter;
