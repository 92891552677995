/* ========================================================================
 * Apricot's Responsive Image Module
 * ======================================================================== */

import Utils from "./CBUtils";

// Using the dataset Property
// The data- prefix is removed from the attribute name
// Any hyphen followed by a lower case letter is removed from the name and the letter following it is converted to uppercase
// Other characters will remain unchanged. This means that any hyphen that is not followed by a lowercase letter will also remain unchanged.

/**
 * Image loader per breakpoint
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.cbViewport
 * @param {Boolean} data.cbBreakpoint
 * @param {Boolean} data.cbMobileFirst
 * @param {Boolean} data.cbImageOnly
 * @param {String} data.cbDefaultImage
 * @param {String} data.cbXsImage
 * @param {String} data.cbSmImage
 * @param {String} data.cbMdImage
 * @param {String} data.cbLgImage
 * @param {String} data.cbXlImage
 * @param {String} data.cbXl2Image
 * @param {String} data.cbXl3Image
 * @param {String} data.cbPath
 * @param {Boolean} data.cbRetina
 * @param {String} data.cbRetinaExt
 * @returns {{getImage: Function}}
 * @returns {{destroy: Function}}
 */
const ResponsiveImage = (data = {}) => {
  const defaultData = {
    elem: null,
    cbViewport: true,
    cbBreakpoint: true,
    cbMobileFirst: true,
    cbImageOnly: false,

    cbDefaultImage: "",
    cbXsImage: "",
    cbSmImage: "",
    cbMdImage: "",
    cbLgImage: "",
    cbXlImage: "",
    cbXl2Image: "",
    cbXl3Image: "",
    cbPath: "",

    cbRetina: false,
    cbRetinaExt: "@2x",
  };

  let elem = data.elem;
  if (!Utils.elemExists(elem)) return false;

  const tmp = elem.dataset;
  data = {
    ...defaultData,
    ...data,
  };
  data = {
    ...data,
    ...tmp,
  };

  let cbViewport = Utils.isTrue(data.cbViewport);
  let cbBreakpoint = Utils.isTrue(data.cbBreakpoint);
  let cbMobileFirst = Utils.isTrue(data.cbMobileFirst);
  let cbRetina = Utils.isTrue(data.cbRetina);

  let cbDefaultImage = data.cbDefaultImage;
  let cbPath = data.cbPath;
  let cbRetinaExt = data.cbRetinaExt;
  let cbImage = {};
  cbImage.xs = data.cbXsImage;
  cbImage.sm = data.cbSmImage;
  cbImage.md = data.cbMdImage;
  cbImage.lg = data.cbLgImage;
  cbImage.xl = data.cbXlImage;
  cbImage.xl2 = data.cbXl2Image;
  cbImage.xl3 = data.cbXl3Image;
  cbImage.src = "";

  let imageList = [];
  const retina = Utils.isRetina();
  let elemId = Utils.attr(elem, "id")
    ? Utils.attr(elem, "id")
    : Utils.uniqueID(10, "responsiveImage_");

  const init = () => {
    elem.responsiveImage = "cb";

    Utils.attr(elem, "id", elemId);

    buildImageList();
    getImage();

    if (data.cbImageOnly) {
      return null;
    } else if (cbViewport) {
      // get viewport specific image on first run
      changeImage(Utils.viewport().prefix);
    } else {
      // if we have default
      // assign default
      let firstImg = "";
      if (Utils.isBlank(cbDefaultImage)) {
        firstImg = getImgSource();
      } else {
        firstImg = cbPath + cbDefaultImage;
      }

      setImage(firstImg);
    }

    if (!cbBreakpoint) {
      const event = new CustomEvent("apricot_noImageChange");
      elem.dispatchEvent(event);
    }

    if (cbBreakpoint) {
      activateBreakpoints();
    }
  };

  const activateBreakpoints = () => {
    //Initialize apricot_breakpointChange event
    Utils.breakpoints();

    //Listen to viewport changes
    document.addEventListener("apricot_breakpointChange", function (e) {
      if (!Utils.isEmptyObject(e.data)) {
        changeImage(e.data.prefix);
      }
    });
  };

  const buildImageList = () => {
    for (const [key] of Object.entries(cbImage)) {
      if (!Utils.isBlank(cbImage[key])) {
        imageList.push(getImageObj(key));
      }
    }
  };

  const getImageObj = (prefix) => {
    let imageObj = {};
    let image = "";

    prefix = adjustPrefix(prefix);
    image = cbPath + cbImage[prefix];

    // check if device is supporting high-resolution
    // check if user has not disabled this feature
    // get retina for each break point
    if (retina && cbRetina) {
      image = fileName(image) + cbRetinaExt + "." + fileExt(image);
      Utils.addClass(elem, "cb-retina-image");
    }

    imageObj = {
      prefix: prefix,
      url: image,
    };

    return imageObj;
  };

  const fileName = (file) => {
    return file.split(".").slice(0, -1).join(".") || file;
  };

  const fileExt = (file) => {
    var filename = file.split("\\").pop().split("/").pop();

    return filename.substr((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1);
  };

  const changeImage = (prefix) => {
    const url = getImage(prefix);

    // Only change if there is a new image
    // otherwise keep last one
    if (!Utils.isBlank(url)) {
      setImage(url);
    }
  };

  const setImage = (url) => {
    //Check if we are dealing with an image element
    if (elem.tagName === "IMG") {
      Utils.attr(elem, "src", url);
    } else {
      elem.style.backgroundImage = "url(" + url + ")";
    }

    const event = new CustomEvent("apricot_imageChange");
    elem.dispatchEvent(event);
  };

  const getImage = (prefix) => {
    let imageObj = {};
    // By default show src or default image
    let theImage = "";

    prefix = adjustPrefix(prefix);

    // if image is not provided for viewport use default Image
    // if default image is not passes in check for src
    if (Utils.isBlank(cbDefaultImage)) {
      cbDefaultImage = getImgSource();
    } else {
      theImage = cbPath + cbDefaultImage;
    }

    imageObj = checkImageObj(prefix);

    if (!Utils.isEmptyObject(imageObj)) {
      theImage = imageObj.url;
    } else if (cbMobileFirst) {
      if (prefix) {
        imageObj = getResponsivePrefix(prefix);
        if (!Utils.isEmptyObject(imageObj)) {
          theImage = imageObj.url;
        }
      }
    }
    cbImage.src = theImage;

    return theImage;
  };

  const getImgSource = () => {
    let theImage = "";
    if (elem.tagName === "IMG") {
      if (!Utils.isBlank(Utils.attr(elem, "src"))) {
        theImage = Utils.attr(elem, "src");
      }
    } else {
      theImage = elem.style.backgroundImage.replace(/^url\(['"]?/, "").replace(/['"]?\)$/, "");
    }

    return theImage;
  };

  const getResponsivePrefix = (prefix) => {
    prefix = adjustPrefix(prefix);

    const breakpoints = ["xs", "sm", "md", "lg", "xl", "xl2", "xl3"];
    const pos = breakpoints.findIndex((br) => br === prefix);
    let theObj = {};

    for (let i = pos; i >= 0; i--) {
      theObj = checkImageObj(breakpoints[i]);
      if (!Utils.isEmptyObject(theObj)) {
        return theObj;
      }
    }
    // if there is no smaller image
    // check larger breakpoints
    if (Utils.isEmptyObject(theObj)) {
      for (let i = pos; i <= breakpoints.length; i++) {
        theObj = checkImageObj(breakpoints[i]);
        if (!Utils.isEmptyObject(theObj)) {
          return theObj;
        }
      }
    }

    return theObj;
  };

  const adjustPrefix = (prefix) => {
    return prefix === "2xl" ? "xl2" : prefix === "3xl" ? "xl3" : prefix;
  };

  const checkImageObj = (prefix) => {
    for (var image in imageList) {
      if (imageList[image].prefix === prefix) {
        return imageList[image];
      }
    }

    return {};
  };

  const destroy = () => {
    if (elem.responsiveImage === "cb") {
      elem.responsiveImage = null;

      elem.removeEventListener("apricot_noImageChange");
      elem.removeEventListener("apricot_imageChange");

      // set default image
      if (elem.tagName === "IMG") {
        Utils.attr(elem, "src", cbImage.src);
      } else {
        elem.style.backgroundImage = plugin.$el.css("background-image", "url(" + cbImage.src + ")");
      }
    }
  };

  if (elem.responsiveImage !== "cb") {
    init();
  }

  return {
    destroy: destroy,
    getImage: getImage,
  };
};

export default ResponsiveImage;
