/* ========================================================================
 * Apricot's Tooltip
 * ========================================================================
 *
 * This plugin is depended on
 * https://github.com/FezVrasta/popper.js
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/tooltip.scss";

// javaScript
import { createPopper } from "@popperjs/core";
import Utils from "./CBUtils";

/**
 * Tooltip
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.tooltipNode
 * @param {String} data.placement
 * @param {Number|Object} data.delay
 * @param {Boolean} data.html
 * @param {String} data.template
 * @param {String|HTMLElement|TitleFunction} data.title
 * @param {String} data.innerSelector
 * @param {String} data.trigger
 * @param {Array} data.offset
 * @param {Array} data.flipVariations
 * @param {HTMLElement|String|false} data.container
 * @param {Array} modifiers
 * @param {String} data.style
 * @returns {{show: Function}}
 * @returns {{hide: Function}}
 * @returns {{deactivate: Function}}
 * @returns {{toggle: Function}}
 * @returns {{updateTitle: Function}}
 * @returns {{destroy: Function}}
 */

const Tooltip = (data = {}) => {
  const defaultData = {
    elem: null,
    tooltipNode: null,
    placement: "top",
    delay: {
      show: 200,
      hide: 100,
    },
    html: false,
    template: '<div class="cb-tooltip" role="tooltip"><div class="cb-tooltip-inner"></div></div>',
    innerSelector: ".cb-tooltip-inner",
    title: "",
    trigger: "hover focus",
    offset: [0, 12],
    flipVariations: ["top", "bottom"],
    container: false,
    modifiers: [],
    style: "",
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let tooltipNode = null;

  let events = [];

  if (!Utils.elemExists(elem)) return null;

  // set initial state
  let isOpen = false;
  let isOpening = false;
  let isActive = true;

  let delay = data.delay;

  let showTimeout = null;
  let popperInstance = null;
  // get title
  let title = null;

  const init = () => {
    elem.tooltip = "cb";

    // get title
    title = elem.getAttribute("data-cb-title") || elem.getAttribute("title") || data.title;
    tooltipNode =
      data.tooltipNode || document.querySelector(`#${elem.getAttribute("aria-controls")}`);
    Utils.attr(elem, "data-cb-describedby", Utils.attr(elem, "aria-describedby"));

    a11y();

    // get events list
    events =
      typeof data.trigger === "string"
        ? data.trigger
            .split(" ")
            .filter((trigger) => ["click", "hover", "focus"].indexOf(trigger) !== -1)
        : [];

    // a11y - Tooltip has to open on focus
    events.push("focus");
    events = [...new Set(events)];

    setEventListeners(events);
  };

  const a11y = () => {
    if (!tooltipNode) return;

    Utils.removeClass(tooltipNode, "hidden");
    Utils.removeClass(tooltipNode, "cb-hidden");

    const tooltipId = tooltipNode.getAttribute("id") || Utils.uniqueID(5, "apricot_");

    tooltipNode.setAttribute("id", tooltipId);
    tooltipNode.setAttribute("aria-hidden", "true");
    elem.setAttribute("aria-controls", tooltipId);
    updateAriaDescribedBy(tooltipId);

    if (data.style) {
      Utils.addClass(tooltipNode, data.style);
    }
  };

  const setEventListeners = (events) => {
    const directEvents = [];
    const oppositeEvents = [];

    events.forEach((event) => {
      switch (event) {
        case "hover":
          directEvents.push("mouseenter");
          oppositeEvents.push("mouseleave");
          break;
        case "focus":
          directEvents.push("focus");
          oppositeEvents.push("blur");
          break;
        case "click":
          directEvents.push("click");
          oppositeEvents.push("click");
          break;
      }
    });

    // schedule show tooltip
    directEvents.forEach((event) => {
      const func = (evt) => {
        if (isOpening === true) {
          return;
        }
        evt.usedByTooltip = true;
        if (event === "focus") {
          elem.setAttribute("data-cb-event", "focus");
        }
        scheduleShow(evt);
      };
      events.push({
        event,
        func,
      });
      elem.addEventListener(event, func);
    });

    // schedule hide tooltip
    oppositeEvents.forEach((event) => {
      const func = (evt) => {
        if (evt.usedByTooltip === true) {
          return;
        }

        if (event === "blur") {
          elem.removeAttribute("data-cb-event");
        } else if (elem.getAttribute("data-cb-event") === "focus") {
          return;
        }
        scheduleHide(evt);
      };
      events.push({
        event,
        func,
      });
      elem.addEventListener(event, func);
    });
  };

  const setTooltipNodeEvent = (evt) => {
    const relatedReference = evt.relatedReference || evt.toElement || evt.relatedTarget;

    const callback = (evt2) => {
      const relatedReference2 = evt2.relatedReference || evt2.toElement || evt2.relatedTarget;

      // Remove event listener after call
      tooltipNode.removeEventListener(evt.type, callback);

      // If the new reference is not the reference element
      if (!elem.contains(relatedReference2)) {
        // Schedule to hide tooltip
        scheduleHide(evt2);
      }
    };

    if (tooltipNode.contains(relatedReference)) {
      // listen to mouseleave on the tooltip element to be able to hide the tooltip
      tooltipNode.addEventListener(evt.type, callback);
      return true;
    }

    return false;
  };

  const scheduleShow = (evt) => {
    isOpening = true;
    // defaults to 0
    const computedDelay = (delay && delay.show) || delay || 0;
    showTimeout = window.setTimeout(() => {
      show();
    }, computedDelay);
  };

  const scheduleHide = (evt) => {
    isOpening = false;
    // defaults to 0
    const computedDelay = (delay && delay.hide) || delay || 0;
    window.clearTimeout(showTimeout);
    window.setTimeout(() => {
      if (isOpen === false) {
        return;
      }
      if (!document.body.contains(tooltipNode)) {
        return;
      }

      // if we are hiding because of a mouseleave, we must check that the new
      // reference isn't the tooltip, because in this case we don't want to hide it
      if (evt.type === "mouseleave") {
        const isSet = setTooltipNodeEvent(evt);

        // if we set the new event, don't hide the tooltip yet
        // the new event will take care to hide it if necessary
        if (isSet) {
          return;
        }
      }

      hide();
    }, computedDelay);
  };

  const createTooltip = () => {
    let tipNode = null;
    let tooltipGenerator = null;

    // create tip element
    tooltipGenerator = window.document.createElement("div");
    tooltipGenerator.innerHTML = data.template.trim();
    tipNode = tooltipGenerator.childNodes[0];

    // add unique ID to our tooltip (needed for accessibility reasons)
    tipNode.id = Utils.uniqueID(5, "apricot_");

    // add title to tooltip
    const titleNode = tooltipGenerator.querySelector(data.innerSelector);
    addContent(title, titleNode, data.html);

    // Adjust style
    if (data.style) {
      Utils.addClass(tipNode, data.style);
    }

    // return the generated tooltip node
    return tipNode;
  };

  const addContent = (title, titleNode, allowHtml) => {
    if (title.nodeType === 1 || title.nodeType === 11) {
      // if title is a element node or document fragment, append it only if allowHtml is true
      allowHtml && titleNode.appendChild(title);
    } else if (Utils.isFunction(title)) {
      // Recursively call ourself so that the return value of the function gets handled appropriately - either
      // as a dom node, a string, or even as another function.
      addContent(title.call(elem), titleNode, allowHtml);
    } else {
      // if it's just a simple text, set textContent or innerHtml depending by `allowHtml` value
      allowHtml ? (titleNode.innerHTML = title) : (titleNode.textContent = title);
    }
  };

  // tip will be added to this
  const findContainer = () => {
    let container = null;
    // if container is a query, get the relative element
    if (typeof data.container === "string") {
      container = window.document.querySelector(data.container);
    } else if (data.container === false) {
      // if container is `false`, set it to reference parent

      container = elem.parentNode;
    }

    return container;
  };

  const clearContent = (lastTitle, titleNode, allowHtml) => {
    if (lastTitle.nodeType === 1 || lastTitle.nodeType === 11) {
      allowHtml && titleNode.removeChild(lastTitle);
    } else {
      allowHtml ? (titleNode.innerHTML = "") : (titleNode.textContent = "");
    }
  };

  const updateAriaDescribedBy = (id) => {
    let ariaDescribedby = Utils.attr(elem, "data-cb-describedby") || null;
    if (id) {
      if (ariaDescribedby) {
        Utils.attr(elem, "aria-describedby", `${id} ${ariaDescribedby}`);
      } else {
        Utils.attr(elem, "aria-describedby", id);
      }
    } else if (ariaDescribedby) {
      Utils.attr(elem, "aria-describedby", ariaDescribedby);
    } else {
      elem.removeAttribute("aria-describedby");
    }
  };

  /**
   * Reveals a tooltip. This is considered a "manual" triggering of the tooltip.
   * Tooltips with zero-length titles are never displayed.
   * @method Tooltip#show
   * @memberof Tooltip
   */
  const show = () => {
    // check if we should proceed
    if (!isActive) {
      return;
    }
    // don't show if it's already visible
    // or if it's not being showed
    if (isOpen && !isOpening) {
      return;
    }

    isOpen = true;

    // if the tooltipNode already exists, just show it
    if (tooltipNode && popperInstance) {
      tooltipNode.style.visibility = "visible";
      tooltipNode.setAttribute("aria-hidden", "false");
      popperInstance.forceUpdate();

      return;
    }

    // don't show tooltip if no title is defined
    // if the plugin creates the tip
    // if (!title) {
    //   return;
    // }

    // create tooltip node
    if (!tooltipNode) {
      tooltipNode = createTooltip();
      // Add `aria-describedby` to our reference element for accessibility reasons
      // elem.setAttribute('aria-describedby', tooltipNode.id);
      updateAriaDescribedBy(tooltipNode.id);

      elem.setAttribute("aria-controls", tooltipNode.id);

      // append tooltip to container
      const container = findContainer();
      container.appendChild(tooltipNode);
    }

    tooltipNode.setAttribute("aria-hidden", "false");
    tooltipNode.style.visibility = "visible";

    let placementOpt = elem.getAttribute("data-cb-placement") || data.placement;

    // offset
    const offsetObj = {
      name: "offset",
      options: {
        offset: data.offset,
      },
    };

    // flip
    const flipObj = {
      name: "flip",
      options: {
        fallbackPlacements: data.flipVariations,
      },
    };

    // const PerformanceObj = { name: 'eventListeners', enabled: true }
    const modifiersArr = [
      flipObj,
      offsetObj,
      // PerformanceObj
    ];

    const popperOptions = {
      placement: placementOpt,
      modifiers: [...modifiersArr, ...data.modifiers],
    };

    popperInstance = createPopper(elem, tooltipNode, popperOptions);
  };

  /**
   * Hides an element’s tooltip. This is considered a “manual” triggering of the tooltip.
   * @method Tooltip#hide
   * @memberof Tooltip
   */
  const hide = () => {
    // don't hide if it's already hidden
    if (!isOpen) {
      return this;
    }

    isOpen = false;

    // hide tooltipNode
    tooltipNode.style.visibility = "hidden";
    tooltipNode.setAttribute("aria-hidden", "true");
  };

  const dispose = () => {
    // remove event listeners first to prevent any unexpected behaviour
    isActive = false;
    events.forEach(({ func, event }) => {
      if (event) {
        elem.removeEventListener(event, func);
      }
    });
    events = [];

    hide();
    if (tooltipNode) {
      // destroy tooltipNode
      tooltipNode.parentNode && tooltipNode.parentNode.removeChild(tooltipNode);
      // destroys
      popperInstance && popperInstance.destroy();
      // elem.removeAttribute('aria-describedby');
      updateAriaDescribedBy(null);
      elem.removeAttribute("aria-controls");

      tooltipNode = null;
    }
  };

  /**
   * Updates the tooltip's title content
   * @method Tooltip#updateTitle
   * @memberof Tooltip
   * @param {String|HTMLElement} title - The new content to use for the title
   */
  const updateTitle = (title) => {
    if (typeof tooltipNode === "undefined") {
      if (typeof data.title !== "undefined") {
        data.title = title;
      }
      return;
    }

    const titleNode = tooltipNode.querySelector(data.innerSelector);
    clearContent(title, titleNode, data.html);
    addContent(title, titleNode, data.html);
    data.title = title;
  };

  /**
   * Deactivate the tooltip
   * @method Tooltip#deactivate
   * @memberof Tooltip
   * @param {Boolean} mode - If true deactivate, else activate back
   */
  const deactivate = (mode) => {
    isActive = !mode;
  };

  /**
   * Toggles an element’s tooltip. This is considered a “manual” triggering of the tooltip.
   * @method Tooltip#toggle
   * @memberof Tooltip
   */
  const toggle = () => {
    if (!isActive) {
      return;
    }

    if (isOpen) {
      return hide();
    } else {
      return show();
    }
  };

  /**
   * Destroy  tooltip plugin.
   * @method Tooltip#destroy
   * @memberof Tooltip
   */
  const destroy = () => {
    if (elem.tooltip === "cb") {
      elem.tooltip = null;

      dispose();
    }
  };

  if (elem.tooltip !== "cb") {
    init();
  }

  return {
    show: show,
    hide: hide,
    deactivate: deactivate,
    toggle: toggle,
    updateTitle: updateTitle,
    destroy: destroy,
  };
};

export default Tooltip;
