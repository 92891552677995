/* ========================================================================
 * Apricot's International Phone
 * ======================================================================== */

// SCSS
import "../scss/includes/forms-international-phone.scss";

// javaScript
import Utils from "./CBUtils";
// Same as CBData
const countriesList = [
  {
    name: "Afghanistan",
    iso: "af",
    dialCode: "93",
  },
  {
    name: "Albania",
    iso: "al",
    dialCode: "355",
  },
  {
    name: "Algeria",
    iso: "dz",
    dialCode: "213",
  },
  {
    name: "Andorra",
    iso: "ad",
    dialCode: "376",
  },
  {
    name: "Angola",
    iso: "ao",
    dialCode: "244",
  },
  {
    name: "Antigua and Barbuda",
    iso: "ag",
    dialCode: "1268",
  },
  {
    name: "Argentina",
    iso: "ar",
    dialCode: "54",
  },
  {
    name: "Armenia",
    iso: "am",
    dialCode: "374",
  },
  {
    name: "Australia",
    iso: "au",
    dialCode: "61",
  },
  {
    name: "Austria",
    iso: "at",
    dialCode: "43",
  },
  {
    name: "Azerbaijan",
    iso: "az",
    dialCode: "994",
  },
  {
    name: "Bahamas",
    iso: "bs",
    dialCode: "1242",
  },
  {
    name: "Bahrain",
    iso: "bh",
    dialCode: "973",
  },
  {
    name: "Bangladesh",
    iso: "bd",
    dialCode: "880",
  },
  {
    name: "Barbados",
    iso: "bb",
    dialCode: "1246",
  },
  {
    name: "Belarus",
    iso: "by",
    dialCode: "375",
  },
  {
    name: "Belgium",
    iso: "be",
    dialCode: "32",
  },
  {
    name: "Belize",
    iso: "bz",
    dialCode: "501",
  },
  {
    name: "Benin",
    iso: "bj",
    dialCode: "229",
  },
  {
    name: "Bhutan",
    iso: "bt",
    dialCode: "975",
  },
  {
    name: "Bolivia",
    iso: "bo",
    dialCode: "591",
  },
  {
    name: "Bosnia and Herzegovina",
    iso: "ba",
    dialCode: "387",
  },
  {
    name: "Botswana",
    iso: "bw",
    dialCode: "267",
  },
  {
    name: "Brazil",
    iso: "br",
    dialCode: "55",
  },
  {
    name: "Brunei Darussalam",
    iso: "bn",
    dialCode: "673",
  },
  {
    name: "Bulgaria",
    iso: "bg",
    dialCode: "359",
  },
  {
    name: "Burkina Faso",
    iso: "bf",
    dialCode: "226",
  },
  {
    name: "Myanmar",
    iso: "mm",
    dialCode: "95",
  },
  {
    name: "Burundi",
    iso: "bi",
    dialCode: "257",
  },
  {
    name: "Cape Verde",
    iso: "cv",
    dialCode: "238",
  },
  {
    name: "Cambodia",
    iso: "kh",
    dialCode: "855",
  },
  {
    name: "Cameroon",
    iso: "cm",
    dialCode: "237",
  },
  {
    name: "Canada",
    iso: "ca",
    dialCode: "1",
  },
  {
    name: "Central African Republic",
    iso: "cf",
    dialCode: "236",
  },
  {
    name: "Chad",
    iso: "td",
    dialCode: "235",
  },
  {
    name: "Chile",
    iso: "cl",
    dialCode: "56",
  },
  {
    name: "China",
    iso: "cn",
    dialCode: "86",
  },
  {
    name: "Colombia",
    iso: "co",
    dialCode: "57",
  },
  {
    name: "Comoros",
    iso: "km",
    dialCode: "269",
  },
  {
    name: "Congo, The Democratic Republic Of The",
    iso: "cd",
    dialCode: "243",
  },
  {
    name: "Congo",
    iso: "cg",
    dialCode: "242",
  },
  {
    name: "Costa Rica",
    iso: "cr",
    dialCode: "506",
  },
  {
    name: "Cote D'Ivoire",
    iso: "ci",
    dialCode: "225",
  },
  {
    name: "Croatia",
    iso: "hr",
    dialCode: "385",
  },
  {
    name: "Cuba",
    iso: "cu",
    dialCode: "53",
  },
  {
    name: "Cyprus",
    iso: "cy",
    dialCode: "357",
  },
  {
    name: "Czechia",
    iso: "cz",
    dialCode: "420",
  },
  {
    name: "Denmark",
    iso: "dk",
    dialCode: "45",
  },
  {
    name: "Djibouti",
    iso: "dj",
    dialCode: "253",
  },
  {
    name: "Dominica",
    iso: "dm",
    dialCode: "1767",
  },
  {
    name: "Dominican Republic",
    iso: "do",
    dialCode: "1",
  },
  {
    name: "Ecuador",
    iso: "ec",
    dialCode: "593",
  },
  {
    name: "Egypt",
    iso: "eg",
    dialCode: "20",
  },
  {
    name: "El Salvador",
    iso: "sv",
    dialCode: "503",
  },
  {
    name: "Equatorial Guinea",
    iso: "gq",
    dialCode: "240",
  },
  {
    name: "Eritrea",
    iso: "er",
    dialCode: "291",
  },
  {
    name: "Estonia",
    iso: "ee",
    dialCode: "372",
  },
  {
    name: "Ethiopia",
    iso: "et",
    dialCode: "251",
  },
  {
    name: "Fiji",
    iso: "fj",
    dialCode: "679",
  },
  {
    name: "Finland",
    iso: "fi",
    dialCode: "358",
  },
  {
    name: "France",
    iso: "fr",
    dialCode: "33",
  },
  {
    name: "Gabon",
    iso: "ga",
    dialCode: "241",
  },
  {
    name: "Gambia",
    iso: "gm",
    dialCode: "220",
  },
  {
    name: "Georgia",
    iso: "ge",
    dialCode: "995",
  },
  {
    name: "Germany",
    iso: "de",
    dialCode: "49",
  },
  {
    name: "Ghana",
    iso: "gh",
    dialCode: "233",
  },
  {
    name: "Greece",
    iso: "gr",
    dialCode: "30",
  },
  {
    name: "Grenada",
    iso: "gd",
    dialCode: "1473",
  },
  {
    name: "Guatemala",
    iso: "gt",
    dialCode: "502",
  },
  {
    name: "Guinea",
    iso: "gn",
    dialCode: "224",
  },
  {
    name: "Guinea-Bissau",
    iso: "gw",
    dialCode: "245",
  },
  {
    name: "Guyana",
    iso: "gy",
    dialCode: "592",
  },
  {
    name: "Haiti",
    iso: "ht",
    dialCode: "509",
  },
  {
    name: "Holy See (Vatican City State)",
    iso: "va",
    dialCode: "39",
  },
  {
    name: "Honduras",
    iso: "hn",
    dialCode: "504",
  },
  {
    name: "Hong Kong",
    iso: "hk",
    dialCode: "344",
  },
  {
    name: "Hungary",
    iso: "hu",
    dialCode: "36",
  },
  {
    name: "Iceland",
    iso: "is",
    dialCode: "354",
  },
  {
    name: "India",
    iso: "in",
    dialCode: "91",
  },
  {
    name: "Indonesia",
    iso: "id",
    dialCode: "62",
  },
  {
    name: "Iran, Islamic Republic Of",
    iso: "ir",
    dialCode: "98",
  },
  {
    name: "Iraq",
    iso: "iq",
    dialCode: "964",
  },
  {
    name: "Ireland",
    iso: "ie",
    dialCode: "353",
  },
  {
    name: "Israel",
    iso: "il",
    dialCode: "972",
  },
  {
    name: "Italy",
    iso: "it",
    dialCode: "39",
  },
  {
    name: "Jamaica",
    iso: "jm",
    dialCode: "1876",
  },
  {
    name: "Japan",
    iso: "jp",
    dialCode: "81",
  },
  {
    name: "Jordan",
    iso: "jo",
    dialCode: "962",
  },
  {
    name: "Kazakhstan",
    iso: "kz",
    dialCode: "7",
  },
  {
    name: "Kenya",
    iso: "ke",
    dialCode: "254",
  },
  {
    name: "Kiribati",
    iso: "ki",
    dialCode: "686",
  },
  {
    name: "Korea, North (DPR)",
    iso: "kp",
    dialCode: "850",
  },
  {
    name: "Korea, South (ROK)",
    iso: "kr",
    dialCode: "82",
  },
  {
    name: "Kosovo",
    iso: "xk",
    dialCode: "381",
  },
  {
    name: "Kuwait",
    iso: "kw",
    dialCode: "965",
  },
  {
    name: "Kyrgyzstan",
    iso: "kg",
    dialCode: "996",
  },
  {
    name: "Lao People's Democratic Republic",
    iso: "la",
    dialCode: "856",
  },
  {
    name: "Latvia",
    iso: "lv",
    dialCode: "371",
  },
  {
    name: "Lebanon",
    iso: "lb",
    dialCode: "961",
  },
  {
    name: "Lesotho",
    iso: "ls",
    dialCode: "266",
  },
  {
    name: "Liberia",
    iso: "lr",
    dialCode: "231",
  },
  {
    name: "Libya",
    iso: "ly",
    dialCode: "218",
  },
  {
    name: "Liechtenstein",
    iso: "li",
    dialCode: "423",
  },
  {
    name: "Lithuania",
    iso: "lt",
    dialCode: "370",
  },
  {
    name: "Luxembourg",
    iso: "lu",
    dialCode: "352",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic Of",
    iso: "mk",
    dialCode: "389",
  },
  {
    name: "Madagascar",
    iso: "mg",
    dialCode: "261",
  },
  {
    name: "Malawi",
    iso: "mw",
    dialCode: "265",
  },
  {
    name: "Malaysia",
    iso: "my",
    dialCode: "60",
  },
  {
    name: "Maldives",
    iso: "mv",
    dialCode: "960",
  },
  {
    name: "Mali",
    iso: "ml",
    dialCode: "223",
  },
  {
    name: "Malta",
    iso: "mt",
    dialCode: "356",
  },
  {
    name: "Marshall Islands",
    iso: "mh",
    dialCode: "692",
  },
  {
    name: "Mauritania",
    iso: "mr",
    dialCode: "222",
  },
  {
    name: "Mauritius",
    iso: "mu",
    dialCode: "230",
  },
  {
    name: "Mexico",
    iso: "mx",
    dialCode: "52",
  },
  {
    name: "Micronesia, Federated States Of",
    iso: "fm",
    dialCode: "691",
  },
  {
    name: "Moldova, Republic Of",
    iso: "md",
    dialCode: "373",
  },
  {
    name: "Monaco",
    iso: "mc",
    dialCode: "377",
  },
  {
    name: "Mongolia",
    iso: "mn",
    dialCode: "976",
  },
  {
    name: "Montenegro",
    iso: "me",
    dialCode: "382",
  },
  {
    name: "Morocco",
    iso: "ma",
    dialCode: "212",
  },
  {
    name: "Mozambique",
    iso: "mz",
    dialCode: "258",
  },
  {
    name: "Namibia",
    iso: "na",
    dialCode: "264",
  },
  {
    name: "Nauru",
    iso: "nr",
    dialCode: "674",
  },
  {
    name: "Nepal",
    iso: "np",
    dialCode: "977",
  },
  {
    name: "Netherlands",
    iso: "nl",
    dialCode: "31",
  },
  {
    name: "New Zealand",
    iso: "nz",
    dialCode: "64",
  },
  {
    name: "Nicaragua",
    iso: "ni",
    dialCode: "505",
  },
  {
    name: "Niger",
    iso: "ne",
    dialCode: "227",
  },
  {
    name: "Nigeria",
    iso: "ng",
    dialCode: "234",
  },
  {
    name: "Norway",
    iso: "no",
    dialCode: "47",
  },
  {
    name: "Oman",
    iso: "om",
    dialCode: "968",
  },
  {
    name: "Pakistan",
    iso: "pk",
    dialCode: "92",
  },
  {
    name: "Palau",
    iso: "pw",
    dialCode: "680",
  },
  {
    name: "Panama",
    iso: "pa",
    dialCode: "507",
  },
  {
    name: "Papua New Guinea",
    iso: "pg",
    dialCode: "675",
  },
  {
    name: "Paraguay",
    iso: "py",
    dialCode: "595",
  },
  {
    name: "Peru",
    iso: "pe",
    dialCode: "51",
  },
  {
    name: "Philippines",
    iso: "ph",
    dialCode: "63",
  },
  {
    name: "Poland",
    iso: "pl",
    dialCode: "48",
  },
  {
    name: "Portugal",
    iso: "pt",
    dialCode: "351",
  },
  {
    name: "Qatar",
    iso: "qa",
    dialCode: "974",
  },
  {
    name: "Romania",
    iso: "ro",
    dialCode: "40",
  },
  {
    name: "Russian Federation",
    iso: "ru",
    dialCode: "7",
  },
  {
    name: "Rwanda",
    iso: "rw",
    dialCode: "250",
  },
  {
    name: "Saint Kitts and Nevis",
    iso: "kn",
    dialCode: "1869",
  },
  {
    name: "Saint Lucia",
    iso: "lc",
    dialCode: "1758",
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso: "vc",
    dialCode: "1784",
  },
  {
    name: "Samoa",
    iso: "ws",
    dialCode: "685",
  },
  {
    name: "San Marino",
    iso: "sm",
    dialCode: "378",
  },
  {
    name: "Sao Tome and Principe",
    iso: "st",
    dialCode: "239",
  },
  {
    name: "Saudi Arabia",
    iso: "sa",
    dialCode: "966",
  },
  {
    name: "Senegal",
    iso: "sn",
    dialCode: "221",
  },
  {
    name: "Serbia",
    iso: "rs",
    dialCode: "381",
  },
  {
    name: "Seychelles",
    iso: "sc",
    dialCode: "248",
  },
  {
    name: "Sierra Leone",
    iso: "sl",
    dialCode: "232",
  },
  {
    name: "Singapore",
    iso: "sg",
    dialCode: "65",
  },
  {
    name: "Slovakia",
    iso: "sk",
    dialCode: "421",
  },
  {
    name: "Slovenia",
    iso: "si",
    dialCode: "386",
  },
  {
    name: "Solomon Islands",
    iso: "sb",
    dialCode: "677",
  },
  {
    name: "Somalia",
    iso: "so",
    dialCode: "252",
  },
  {
    name: "South Africa",
    iso: "za",
    dialCode: "27",
  },
  {
    name: "South Sudan",
    iso: "ss",
    dialCode: "211",
  },
  {
    name: "Spain",
    iso: "es",
    dialCode: "34",
  },
  {
    name: "Sri Lanka",
    iso: "lk",
    dialCode: "94",
  },
  {
    name: "Sudan",
    iso: "sd",
    dialCode: "249",
  },
  {
    name: "Suriname",
    iso: "sr",
    dialCode: "597",
  },
  {
    name: "Swaziland",
    iso: "sz",
    dialCode: "268",
  },
  {
    name: "Sweden",
    iso: "se",
    dialCode: "46",
  },
  {
    name: "Switzerland",
    iso: "ch",
    dialCode: "41",
  },
  {
    name: "Syrian Arab Republic",
    iso: "sy",
    dialCode: "963",
  },
  {
    name: "Taiwan",
    iso: "tw",
    dialCode: "886",
  },
  {
    name: "Tajikistan",
    iso: "tj",
    dialCode: "992",
  },
  {
    name: "Tanzania, United Republic Of",
    iso: "tz",
    dialCode: "255",
  },
  {
    name: "Thailand",
    iso: "th",
    dialCode: "66",
  },
  {
    name: "Timor-Leste",
    iso: "tl",
    dialCode: "670",
  },
  {
    name: "Togo",
    iso: "tg",
    dialCode: "228",
  },
  {
    name: "Tonga",
    iso: "to",
    dialCode: "676",
  },
  {
    name: "Trinidad and Tobago",
    iso: "tt",
    dialCode: "1868",
  },
  {
    name: "Tunisia",
    iso: "tn",
    dialCode: "216",
  },
  {
    name: "Turkey",
    iso: "tr",
    dialCode: "90",
  },
  {
    name: "Turkmenistan",
    iso: "tm",
    dialCode: "993",
  },
  {
    name: "Tuvalu",
    iso: "tv",
    dialCode: "688",
  },
  {
    name: "Uganda",
    iso: "ug",
    dialCode: "256",
  },
  {
    name: "Ukraine",
    iso: "ua",
    dialCode: "380",
  },
  {
    name: "United Arab Emirates",
    iso: "ae",
    dialCode: "971",
  },
  {
    name: "United Kingdom",
    iso: "gb",
    dialCode: "44",
  },
  {
    name: "United States",
    iso: "us",
    dialCode: "1",
  },
  {
    name: "Uruguay",
    iso: "uy",
    dialCode: "598",
  },
  {
    name: "Uzbekistan",
    iso: "uz",
    dialCode: "998",
  },
  {
    name: "Vanuatu",
    iso: "vu",
    dialCode: "678",
  },
  {
    name: "Venezuela",
    iso: "ve",
    dialCode: "58",
  },
  {
    name: "Viet Nam",
    iso: "vn",
    dialCode: "84",
  },
  {
    name: "Yemen",
    iso: "ye",
    dialCode: "967",
  },
  {
    name: "Zambia",
    iso: "zm",
    dialCode: "260",
  },
  {
    name: "Zimbabwe",
    iso: "zw",
    dialCode: "263",
  },
];

// ------------------------------------  INTERNATIONAL PHONE INPUT
/**
 * International Phone Input
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Array} data.countries
 * @param {String} data.defaultCountry
 * @param {Number} data.defaultValue
 * @param {Array} data.preferredCountries
 * @param {String} data.placeholder
 * @returns {{destroy: Function}}
 *
 */
const IntPhone = (data = {}) => {
  const defaultData = {
    elem: null,
    countries: countriesList,
    defaultCountry: "us",
    defaultValue: 0,
    preferredCountries: ["us", "ca"],
    placeholder: "(###) ###-####",
  };

  const elem = data.elem;
  if (!Utils.elemExists(elem)) return false;

  data = {
    ...defaultData,
    ...data,
  };

  let countries = [];
  let preferredCountries = data.preferredCountries;
  let defaultCountry = data.defaultCountry;
  let defaultValue = data.defaultValue;
  let placeholder = data.placeholder;

  let input = null;
  let ul = null;
  let dropdown = null;
  let toggle = null;
  let flag = null;
  let srOnly = null;
  let openIcon = null;
  let closeIcon = null;

  let filterCode = "";
  let filterType = "";
  let countryCode = "";
  let dialCode = 0;
  let disabled = false;

  const init = () => {
    elem.intPhone = "cb";

    ul = elem.querySelector(".cb-country-list");
    if (!Utils.elemExists(ul)) return false;

    dropdown = elem.querySelector(".cb-flag-dropdown");
    toggle = elem.querySelector(".cb-flag-selected");
    input = elem.querySelector("input");

    flag = toggle.querySelector(".cb-flag");
    srOnly = toggle.querySelector(".sr-only");

    if (input.disabled === true) {
      disabled = true;

      Utils.addClass(input, "cb-disabled");
      Utils.attr(toggle, "disabled", "true");
      Utils.attr(toggle, "tabIndex", "-1");
    }

    // if we don't have a list, use default
    // for react component
    countries = data.countries ? data.countries : countriesList;

    const icons = toggle.querySelectorAll(".cb-icon");
    if (icons[0]) {
      openIcon = icons[0];
      if (icons[1]) {
        closeIcon = icons[1];
      }
    }

    processCountryData();
    buildList();

    // Events
    toggleEvents();
    closeOnClickOutside();

    dropdownEvents();
    inputEvents();

    floatingLabelEvents();

    if (Utils.isBlank(defaultCountry)) {
      defaultCountry = "us";
    }

    setActiveFlag(defaultCountry);
    if (defaultValue && parseInt(defaultValue) > 0) {
      const numValue = handlePlaceholderChange(defaultValue);
      setValue(numValue);
    }
  };

  const processCountryData = () => {
    if (preferredCountries.length > 0) {
      let pcArr = [];
      preferredCountries.reverse();

      preferredCountries.forEach((iso, index) => {
        if (countries.find((obj) => obj.iso === iso)) {
          const isoIndex = countries.map((item) => item.iso).indexOf(iso);

          pcArr.push(countries[isoIndex]);
          countries.splice(isoIndex, 1);
        }
      });
      pcArr.forEach((c) => {
        countries.unshift(c);
      });
    }
  };

  const buildList = () => {
    countries.forEach((country, index) => {
      const li = document.createElement("LI");
      Utils.addClass(li, "cb-country");
      Utils.attr(li, "role", "presentation");

      const a = document.createElement("A");
      Utils.attr(a, "data-cb-dial-code", country.dialCode);
      Utils.attr(a, "data-cb-country-code", country.iso);
      Utils.attr(a, "href", "#");
      Utils.attr(a, "role", "option");

      const span1 = document.createElement("SPAN");
      Utils.addClass(span1, "cb-flag");
      Utils.addClass(span1, country.iso);
      Utils.attr(span1, "aria-hidden", "true");
      a.appendChild(span1);

      const span2 = document.createElement("SPAN");
      // Utils.addClass(span1, 'sr-only')
      span2.innerHTML = `${country.name} (+${country.dialCode})`;
      a.appendChild(span2);

      li.appendChild(a);
      ul.appendChild(li);
    });
  };

  const setActiveFlag = (code) => {
    let country = {};

    setValue("");
    Utils.removeAttr(flag, "class");
    Utils.addClass(flag, "cb-flag");

    if (!isNaN(code)) {
      country = getDialCodeData(code);
    } else if (code !== "") {
      country = getCountryData(code);
    }

    if (Utils.isEmptyObject(country)) {
      noFlag();
    } else {
      if (country.iso !== "") {
        countryCode = country.iso;
        dialCode = country.dialCode;

        Utils.addClass(flag, countryCode);
        Utils.attr(toggle, "data-cb-country-code", countryCode);
        Utils.attr(toggle, "data-cb-dial-code", dialCode);

        srOnly.innerHTML = `Country code for ${country.name} is selected`;
        selectListFlag(countryCode);

        // update placeholder
        setPlaceholder(dialCode, placeholder);

        //trigger custom event
        const event = new CustomEvent("apricot_intPhone");
        event.data = country;

        elem.dispatchEvent(event);
      }
    }

    filterCode = "";
  };

  const getCountryData = (iso) => {
    const obj = countries.filter((country) => country.iso === iso)[0];
    if (obj) {
      return obj;
    } else {
      return {};
    }
  };

  const getDialCodeData = (dialCode) => {
    const obj = countries.filter((country) => country.dialCode === dialCode)[0];
    if (obj) {
      return obj;
    } else {
      return {};
    }
  };

  const selectListFlag = (iso) => {
    const item = ul.querySelector(`[data-cb-country-code="${iso}"]`);

    if (Utils.elemExists(item)) {
      Utils.removeClass(ul.querySelector(".cb-selected"), "cb-selected");
      Utils.addClass(item, "cb-selected");
    }
  };

  const openDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // space, down
    if (e.keyCode === 32 || e.keyCode === 40) {
      if (!Utils.hasClass(dropdown, "cb-open")) {
        toggleDropdown(e);
      }
    }
  };

  const adjustDropdownPosition = () => {
    const top = dropdown.getBoundingClientRect().top;
    const mHeight = dropdown.offsetHeight;
    const wHeight = Utils.windowsDimension().height;

    const limit = parseInt(wHeight - top);

    if (mHeight > limit) {
      Utils.addClass(dropdown, "cb-revers");
    } else {
      Utils.removeClass(dropdown, "cb-revers");
    }
  };

  const toggleDropdown = (e) => {
    if (e) e.preventDefault();

    // disabled
    if (disabled) return null;

    // is open -> close
    if (Utils.hasClass(dropdown, "cb-open")) {
      Utils.removeClass(dropdown, "cb-open");
      Utils.removeClass(dropdown, "cb-revers");

      Utils.removeClass(openIcon, "cb-hidden");
      Utils.addClass(closeIcon, "cb-hidden");

      Utils.attr(toggle, "aria-expanded", "false");

      Utils.removeAttr(input, "disabled");
      input.focus();
    } else {
      Utils.attr(input, "disabled", "true");
      Utils.addClass(dropdown, "cb-open");
      adjustDropdownPosition();

      Utils.removeClass(closeIcon, "cb-hidden");
      Utils.addClass(openIcon, "cb-hidden");
      Utils.attr(toggle, "aria-expanded", "true");

      if (Utils.elemExists(ul.querySelector(".cb-selected"))) {
        ul.querySelector(".cb-selected").focus();
      } else {
        ul.querySelectorAll(".cb-country a")[0].focus();
      }
    }
  };

  const toggleEvents = () => {
    toggle.addEventListener("click", toggleDropdown);
    toggle.addEventListener("keyup", openDropdown);
    toggle.addEventListener("focus", toggleFocusAnchor(true));
    toggle.addEventListener("blur", toggleFocusAnchor(false));
  };

  const toggleFocusAnchor = (mode) => {
    if (disabled) return null;
    if (mode) {
      Utils.addClass(dropdown, "cb-focus");
    } else {
      Utils.removeClass(dropdown, "cb-focus");
    }
  };
  const closeOnClickOutside = () => {
    document.addEventListener("keydown", closeA11Y, true);
    document.addEventListener("click", closeA11Y, true);
  };

  const closeA11Y = (e) => {
    if (e.type === "click") {
      if (!Utils.hasClass(dropdown, "cb-open") || dropdown.contains(e.target)) {
        return;
      }
      toggleDropdown();
    } else if (e.keyCode === 27) {
      if (!Utils.hasClass(dropdown, "cb-open")) {
        return;
      }
      toggleDropdown();
    }
  };

  const dropdownEvents = () => {
    ul.querySelectorAll("a").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();

        // flag has change, reset input
        setActiveFlag(Utils.attr(link, "data-cb-country-code"));
        toggleDropdown();
      });

      link.addEventListener("keydown", (e) => {
        const k = e.which || e.keyCode;

        if (k >= Utils.KEYS.ZERO && k <= Utils.KEYS.NINE) {
          filterCode = filterType === "number" ? filterCode : "";
          filterType = "number";
          filterListFlag(String.fromCharCode(e.which), "dial");
        } else if (k >= Utils.KEYS.A && k <= Utils.KEYS.Z) {
          filterCode = filterType === "string" ? filterCode : "";
          filterType = "string";
          filterListFlag(String.fromCharCode(e.which), "country");
        } else if (/(9|16)/.test(k)) {
          //tab/shift
          let index = 0;
          const tabbingBack = e.shiftKey;
          const items = ul.querySelectorAll("a");

          Array.prototype.forEach.call(items, function (item, i) {
            if (link === item) {
              index = i;
            }
          });

          if (k === 9 && tabbingBack && index === 0) {
            //make sure menus are closed after tab away

            toggleDropdown();
          } else if (!tabbingBack && index === items.length - 1) {
            e.preventDefault();

            const newActive = items.item(0);
            newActive.focus();
          } else {
            //up/down arrows
            if (k === 9 && tabbingBack) {
              index--; //up|shift+tab
            } else if (k === 9) {
              index++; //down|tab
            }

            if (index < 0 || index === items.length) {
              return;
            }
          }
        } else if (/(38|40)/.test(k)) {
          //up/down
          let index = 0;
          const items = ul.querySelectorAll("a");

          Array.prototype.forEach.call(items, function (item, i) {
            if (link === item) {
              index = i;
            }
          });

          if (k === 38) {
            index--; //up|shift+tab
          } else if (k === 40) {
            index++; //down|tab
          }

          if (index < 0 || index === items.length) {
            return;
          }

          const newActive = items.item(index);
          newActive.focus();
        }
      });

      link.addEventListener("keyup", (e) => {
        if (Utils.isKey(e, "ESC")) {
          //esc
          e.preventDefault();

          toggleDropdown();
        }
      });
    });
  };

  const floatingLabelEvents = () => {
    const wrapper = Utils.getClosest(elem, ".cb-int-phone");
    if (!Utils.elemExists(wrapper)) return false;

    const isFloating = Utils.hasClass(wrapper, "cb-floating-label");
    const label = wrapper.querySelector("label");

    if (isFloating) {
      // floatingLabel custom event
      input.addEventListener("apricot_inputBlur", () => {
        Utils.addClass(label, "cb-focus-fl");

        setTimeout(() => {
          if (
            toggle === document.activeElement ||
            Utils.hasClass(dropdown, "cb-focus") ||
            Utils.hasClass(dropdown, "cb-open")
          ) {
            // keep it
            Utils.addClass(label, "cb-focus-fl");
          } else {
            // remove it
            Utils.removeClass(label, "cb-focus-fl");
          }
        }, 50);
      });
    }
  };

  const inputEvents = () => {
    input.addEventListener("keydown", inputKeyDown);
    input.addEventListener("keyup", inputKeyUp);
  };

  const inputKeyDown = (e) => {
    if (Utils.isKey(e, "PREV")) {
      //prev
      e.preventDefault();

      toggle.focus();
    } else if (Utils.isKey(e, "DOWN") || Utils.isKey(e, "UP")) {
      //down/up
      e.preventDefault();

      toggleDropdown();
    }
  };

  const inputKeyUp = (e) => {
    const k = e.which || e.keyCode;
    const value = e.target.value;
    let numValue = "";

    if (value !== "") {
      if (
        (k >= Utils.KEYS.SPACE && !e.metaKey) ||
        k === Utils.KEYS.DEL ||
        k === Utils.KEYS.BACKSPACE
      ) {
        // 32 is space, and after that it's all chars (not meta/nav keys)
        // del and backspace may change the number too

        numValue = handlePlaceholderChange(e.target.value);
        setValue(numValue);
      }
    }
  };

  const setPlaceholder = (prefix, value) => {
    if (prefix === "1") {
      const mask = `+${prefix} ${value}`;

      Utils.attr(input, "placeholder", mask);
      Utils.attr(input, "maxlength", mask.length);
    } else {
      Utils.attr(input, "placeholder", `+${prefix}`);
      Utils.removeAttr(input, "maxlength");
    }
  };

  const handlePlaceholderChange = (value) => {
    let newValue = "";
    let strippedValue = "";
    let isInt = false;
    let matchesNumber = false;
    value = value.toString();

    let num = "#";
    value = value.replace(`+${dialCode}`, "");

    // strip all characters
    strippedValue = value.replace(/\D/g, "");

    if (dialCode === "1") {
      for (let i = 0, j = 0; i <= placeholder.length; i++) {
        isInt = !isNaN(parseInt(strippedValue[j]));

        matchesNumber = num.indexOf(placeholder[i]) >= 0;

        if (matchesNumber && isInt) {
          newValue += strippedValue[j++];
        } else if (!isInt && matchesNumber) {
          return newValue;
        } else {
          newValue += placeholder[i];
        }
        if (strippedValue[j] === undefined) {
          break;
        }
      }
    } else {
      newValue = strippedValue;
    }

    return newValue;
  };

  const filterListFlag = (code, type) => {
    let newCode = "";
    let theFlag = null;
    code = code.toLowerCase();

    if (filterCode !== "") {
      if (type === "country") {
        newCode = filterCode.length >= 2 ? code : filterCode + code;
      } else {
        newCode = ul.querySelectorAll(`[data-cb-${type}-code^="${filterCode + code}"]`)
          ? filterCode + code
          : code;
      }

      theFlag = ul.querySelectorAll(`[data-cb-${type}-code^="${newCode}"]`)[0];

      if (theFlag) {
        theFlag.focus();
        filterCode = newCode;
      } else {
        filterCode = "";
      }
    } else {
      theFlag = ul.querySelectorAll(`[data-cb-${type}-code^="${code}"]`)[0];
      if (theFlag) {
        theFlag.focus();
        filterCode = code;
      }
    }
  };

  const noFlag = () => {
    const value = input.value;

    //No country
    if (value === "" || value === "+") {
      if (Utils.attr(toggle, "data-cb-country-code") !== "") {
        Utils.removeClass(flag, Utils.attr(toggle, "data-cb-country-code"));

        Utils.removeAttr(toggle, "data-cb-country-code");
        Utils.removeAttr(toggle, "data-cb-dial-code");
        srOnly.innerHTML = "Select country code";
      }
    }
  };

  const setValue = (value) => {
    if (dialCode > 0 && value !== "") {
      input.value = `+${dialCode} ${value}`;
    } else {
      input.value = value;
    }
  };

  const destroy = () => {
    if (elem.intPhone === "cb") {
      elem.intPhone = null;

      toggle.removeEventListener("click", toggleDropdown);
      toggle.removeEventListener("keyup", openDropdown);
      toggle.removeEventListener("focus", toggleFocusAnchor(true));
      toggle.removeEventListener("blur", toggleFocusAnchor(false));

      input.removeEventListener("keydown", inputKeyDown);
      input.removeEventListener("keyup", inputKeyUp);

      document.removeEventListener("keydown", closeA11Y, true);
      document.removeEventListener("click", closeA11Y, true);

      ul.innerHTML = "";
    }
  };

  if (elem.intPhone !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default IntPhone;
